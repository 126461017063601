/*
 * 업무구분: 영업
 * 화 면 명: MSPFS304M
 * 화면설명: 연금이전신청(타사-당사)(RP230000M.xfdl, RP230001S.xfdl:2300)
 * 작 성 일: 2023.01.16
 * 작 성 자: 이윤석
 */
<template>
  <ur-page-container class="fts" :show-title="false" :title="pHeaderObj.title" :topButton="true">

    <!-- header start -->
    <fs-header
      ref="fsHeader"
      :propObj="pHeaderObj"
    ></fs-header>
    <!-- header end -->
    <!-- main start -->
    <main class="fts-main">
      <ur-box-container direction="column" alignV="start">
        <div class="input-top">
          <div class="left gap40">
            <div class="wrap-input row">
              <label class="emphasis"> 계좌번호 </label>
              <mo-text-field 
                ref="ed_accn_no"
                class="w130"
                :class="lv_ed_accn_no.error ? 'error' : ''"
                v-model="lv_ed_accn_no.value"                 
                @keyup="fn_SearchValid($event)"
                @paste="fn_SearchValid($event, 'P')"
                mask="#######-###"
               />  
              <mo-dropdown 
                ref="ed_acct_gb"
                :items="lv_acct_list" 
                v-model="lv_ed_acct_gb.value"
                class="w306" 
                placeholder=" "
                :disabled="lv_ed_acct_gb.disabled"
              />
            </div>
            <div class="wrap-input row">

              <label class="emphasis"> 비밀번호 </label>

					    <!-- 비밀번호 -->
              <m-trans-key-input
                v-if="isMobile"
                ref="ed_secure_password"
                class="w80"
                :class="lv_ed_secure_password.error ? 'error' : ''"
                v-model.trim="lv_ed_secure_password.value"
                type="numberMax4"          
                start="0" 
                end="-1"
                dialog="Y"  
                :isClear="lv_isClear"
                @masked-txt="fn_SetMaskedTxt"         
                />

              <mo-text-field
                v-else
                ref="ed_secure_password"
                class="w80"
                :class="lv_ed_secure_password.error ? 'error' : ''"
                v-model="lv_ed_secure_password.value"
                underline
                maxlength="4"                               
                placeholder="비밀번호"
                type="password"
                password/>
              <!-- 비밀번호 -->
            </div>
          </div>
          <div class="right">
            <div class="wrap-button row">

              <mo-button class="btn-clear" @click="fn_Init()"> 초기화 </mo-button>
              <mo-button primary class="btn-inquiry" @click="fn_searchList()"> 조회 </mo-button>
<!--              <mo-button @click="fn_ReportPrint()" primary> 전자문서 조회 </mo-button>    -->
            </div>
          </div>
        </div>
      </ur-box-container>

      <!-- fs-cust-info start -->
      <fs-cust-info
        ref="fsCustInfo"
        :propObj="pCustInfoObj"
        @fs-cust-name="fn_SetEdCustName"
        @fs-cust-info="fn_SetEdCustInfo"
      ></fs-cust-info>
      <!-- fs-cust-info end -->

      <ur-box-container direction="column" alignV="start">
        <div class="wrap-table-title">
          <h2 class="table-title flex-sb mr0"> 연금이전신청(타사 → 당사) </h2>
        </div>
        <div class="wrap-table">
          <table class="table w50p th-wsN">
            <tbody>
              <tr>
                <th>
                  <span class="emphasis">기존 금융기관</span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <!-- lv_ed_sbx_bfcer_fnc_inst_list  lv_ed_sbx_bfcer_fnc_inst_cd  -->

                    <mo-dropdown 
                      ref="ed_sbx_bfcer_fnc_inst_cd"
                      :items="lv_ed_sbx_bfcer_fnc_inst_list" 
                      :itemText="'text'" :itemValue="'value'"
                      v-model="lv_ed_sbx_bfcer_fnc_inst_cd.value"
                      class="mw150" 
                      placeholder=" "
                      :disabled="lv_ed_sbx_bfcer_fnc_inst_cd.disabled"
                    />
                                       
                  </div>
                </td>
                <th> 
                  <span>지점명</span> 
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field 
                      class="input-long"
                      v-model="lv_ed_ibx_rmtn_fnc_inst_fof_nm.value"
                      :disabled="lv_ed_ibx_rmtn_fnc_inst_fof_nm.disabled"
                       />
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span class="emphasis">기존 계좌번호</span> 
                </th>
                <td>
                   <div class="wrap-input row">
                    <mo-text-field 
                      ref="ed_ibx_rmvl_obj_bfcer_accn_cntnt"
                      class="input-long"
                      v-model="lv_ed_ibx_rmvl_obj_bfcer_accn_cntnt.value"
                      :disabled="lv_ed_ibx_rmvl_obj_bfcer_accn_cntnt.disabled"
                      maxlength="20"
                       />      
                  </div>
                </td>
                <th>
                  <span class="emphasis">최초 가입일자</span> 
                </th>
                <td>
                  <div class="wrap-input row">
                      <mo-date-picker 
                        ref="ed_cal_fst_ent_ymd"
                        v-model="lv_ed_cal_fst_ent_ymd.value" 
                        disabled
                        :bottom="false"
                      />        
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span>기존 연금계좌유형</span> 
                </th>
                <td colspan="3">
                  <div class="wrap-input type02">
                    <!-- :disabled="lv_ed_rdo_rptType01.disabled"  :disabled="isRdoDisabled"-->
                    <mo-radio-wrapper 
                      :items="ed_rdo_rptType01" 
                      v-model="lv_ed_rdo_rptType01.value" 
                      class="row"
                      :class="lv_ed_rdo_rptType01.error ? 'error' : ''"
                      :disabled="lv_ed_rdo_rptType01.disabled"
                    />                     
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span>가입일 구분</span> 
                </th>
                <td colspan="3">
                  <div class="wrap-input type02">
                    <mo-radio-wrapper 
                      :items="ed_rad_ent_ymd_sc_cd" 
                      v-model="lv_ed_rad_ent_ymd_sc_cd.value" 
                      class="row"
                      :class="lv_ed_rad_ent_ymd_sc_cd.error ? 'error' : ''"
                      :disabled="lv_ed_rad_ent_ymd_sc_cd.disabled"
                    />        
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span>이체의사 확인방법</span> 
                </th>
                <td colspan="3">
                  <div class="wrap-input type02">
                    <mo-radio-wrapper 
                      :items="ed_rad_trsf_opin_cnf_mth_cd"  
                      v-model="lv_ed_rad_trsf_opin_cnf_mth_cd.value" 
                      class="row"
                      :class="lv_ed_rad_trsf_opin_cnf_mth_cd.error ? 'error' : ''"
                      :disabled="lv_ed_rad_trsf_opin_cnf_mth_cd.disabled"
                    />       
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span>취소여부</span> 
                </th>
                <td colspan="3">
                  <div class="wrap-input type02">
                    <mo-radio-wrapper 
                      :items="ed_rad_apl_canc_yn" 
                      v-model="lv_ed_rad_apl_canc_yn.value" 
                      class="row"
                      :class="lv_ed_rad_apl_canc_yn.error ? 'error' : ''"
                      :disabled="lv_ed_rad_apl_canc_yn.disabled"
                    />     
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span>접수경로</span> 
                </th>
                <td colspan="3">
                  <div class="wrap-input type02">
                    <mo-radio-wrapper 
                      :items="ed_rad_acpn_path_cd" 
                      v-model="lv_ed_rad_acpn_path_cd.value" 
                      class="row"
                      :class="lv_ed_rad_acpn_path_cd.error ? 'error' : ''"
                      disabled
                    />
                    <!-- <:disabled="lv_ed_rad_acpn_path_cd.disabled"> -->
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
		  </ur-box-container>   
      <ur-box-container>
        <div class="wrap-button button-page-bottom row">
          <mo-button primary size="large" :disabled="btn_confirm_disabled" @click="fn_confirm"> 확인 </mo-button>
        </div>
      </ur-box-container>
    </main>
    <!-- main end -->

    <!-- alert modal popup -->
    <fs-alert-popup 
      ref="alertPopup"
      :popupObj="pAlertPopupObj"
    ></fs-alert-popup>

  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import FSCommUtil from '~/src/ui/fs/comm/FSCommUtil'
  import FSServiceUtil from '~/src/ui/fs/comm/FSServiceUtil'
  import FSCodeUtil from '~/src/ui/fs/comm/FSCodeUtil'
  import FSInfoUtil from '~/src/ui/fs/comm/FSInfoUtil'

  import FSHeader from '~/src/ui/fs/comm/FSHeader'          // header 영역 (공통)
  import FSAlertPopup from '~/src/ui/fs/comm/FSAlertPopup'  // Alert 팝업 (공통)
  import FSCustInfo from '~/src/ui/fs/comm/FSCustInfo'      // 고객정보 (공통)
  import FSMessageUtil from '@/ui/fs/comm/FSMessageUtil'    // message util

  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPFS304M",
    screenId: "MSPFS304M",
    components: {
      'fs-header': FSHeader,
      'fs-alert-popup': FSAlertPopup,
      'fs-cust-info': FSCustInfo,
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {     
      this.$nextTick(()=>{

        if( !FSCommUtil.gfn_isNull(this.$route.params.acct_gb)) {
          this.lv_ed_acct_gb.value = this.$route.params.acct_gb
        }
        
        if( !FSCommUtil.gfn_isNull(this.$route.params.cust_no)) {
          this.lv_ed_accn_no.value = this.$route.params.cust_no
        }  
        
        if( FSCommUtil.gfn_length(this.lv_ed_accn_no.value) == 10 ) {
          this.fn_SearchValid({key: 'v'})
        }

      })

    },
    mounted() {

      this.fn_Init()
      this.fn_SetCommCode()
      this.fn_searchCode()

      this.fn_setRP230001SDisable(true)

      this.btn_confirm_disabled = true

      this.$bizUtil.insSrnLog('MSPFS304M')
      
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {

        param_acct_gb: '', // 라우터 파람으로 받은 acct_gb

        lv_isProcess: this.getStore('fsStore').getters.getState.isProcess,
        isMobile: window.vue.getStore('deviceState').getters.getIsMobile,
        isMtrans : this.getStore('fsStore').getters.getState.isMtrans, // 보안키피드 적용 여부

        // 공통 객체
        pHeaderObj: {
          title: '연금이전 신청/취소(타사 → 당사)',
          step: '',
        },
        pAlertPopupObj: {},
        lv_commCode: {},
        pCustInfoObj: {},


        // 검색조건
        lv_ed_accn_no: {value:''},        // 계좌번호
        lv_ed_acct_gb: {},                // 계좌종류
        lv_ed_cust_nm: {},                // 고객명
        lv_ed_secure_password: {},        // 비밀번호


        // 계좌정보
        lv_cust_info: {},         // 고객정보
        lv_cust_hom_addr : '',         // 고객집주소

        lv_ep200000_info: {},         // 연금이전 신청/취소정보


        lv_acct_list: [],         // 계좌조회목록
 

        lv_ed_sbx_bfcer_fnc_inst_cd: {},        // 기존 금융기관
        lv_ed_sbx_bfcer_fnc_inst_list: [],    // 기존 금융기관목록
        lv_ed_ibx_rmtn_fnc_inst_fof_nm: {},   // 지점명
        lv_ed_ibx_rmvl_obj_bfcer_accn_cntnt: {},   // 기존 계좌번호
        lv_ed_cal_fst_ent_ymd: {},                // 최초가입일자
        lv_ed_rdo_rptType01: {},                  // 기존 연금계좌유형
        lv_ed_rad_ent_ymd_sc_cd: {},              // 가입일 구분
        lv_ed_rad_trsf_opin_cnf_mth_cd: {},       // 이체의사 확인방법
        lv_ed_rad_apl_canc_yn: {},                // 취소여부

        lv_ed_rad_acpn_path_cd: {},                // 접수경로

        // 고객정보 펼치기/접기
        isActive: false,

        btn_confirm_disabled : true,          //확인 버튼 활성화여부        

        // EAI(PO) 호출 객체
        eaiCommObj: FSCommUtil.gfn_eaiCommObj(),

        isRdoDisabled: true, //알림톡발송 edit 여부

        rdo_smsYn:'',
        lv_isClear: false,    // 보안키패드 초기화
        lv_masked_val: '',    // 마스크 변수 체크

      };
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {

      rdoSmsYn() {
        let rtn= [];
        rtn.push({value: 'Y', text: '예'});
        rtn.push({value: 'N', text: '아니오'});
        return rtn;
      },  

      ed_rdo_rptType01() { // 기존 연금계좌유형
        let rtn = [];
        rtn.push({value: '10', text: '연금저축계좌'});
        rtn.push({value: '20', text: '개인형 IPR'});
        return rtn;
      },
      ed_rad_ent_ymd_sc_cd() { // 가입일 구분
        let rtn = [];
        rtn.push({value: '20', text: '이체하는 계좌의 가입일(타사)'});
        rtn.push({value: '10', text: '이체받을 계좌의 가입일(당사)'});
        return rtn;
      },
      ed_rad_trsf_opin_cnf_mth_cd() { // 이체의사 확인방법
        let rtn = [];
        rtn.push({value: '10', text: '전화통화'});
        rtn.push({value: '20', text: '이체하는 금융기관 방문'});
        return rtn;
      },
      ed_rad_apl_canc_yn() { // 취소여부
        let rtn = [];
        rtn.push({value: 'Y', text: '예'});
        rtn.push({value: 'N', text: '아니오'});
        return rtn;
      },
      ed_rad_acpn_path_cd() { // 접수경로
        let rtn = [];
        rtn.push({value: 'P', text: '플라자'});
        rtn.push({value: 'M', text: '모바일'});
        return rtn;
      },
      isProcessCheck() {
        return this.getStore('fsStore').getters.getState.isProcess
      },
    },
    /***********************************************************************************
     * watch 함수 정의 영역                                                         *
     ***********************************************************************************/
    watch: {
      // 프로세스 체크
      'isProcessCheck': {
        handler(after, before) {
          console.log('isProcessCheck > ', after)
          this.fn_Init()
        },
        deep: true
      },      
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /******************************************************************************
      * Function명 : fn_SetMaskedTxt
      * 설명       : 보안키패드 체크
      ******************************************************************************/
      fn_SetMaskedTxt(val) {
        this.lv_masked_val = val
        if(this.lv_masked_val.length >= 4) {
          this.fn_SearchList()
          this.lv_masked_val = ''
        }
      },
      /******************************************************************************
       * Function명 : fn_SetCommCode, fn_SetCommCodeResult
       * 설명       : 공통코드 세팅
       ******************************************************************************/
      fn_SetCommCode() {
        console.log('fn_SetCommCode...')

          // 이체의견확인방법코드(10:전화통화, 20:이체하는 금융기관 방문)
        let params = [
          {'cId':'425'},
          //{'cId':'211', 'dayChkYn':'N'},
        ]
        FSCodeUtil.commCodeList(this, params, this.fn_SetCommCodeResult)
      },

      fn_SetCommCodeResult(pResult) {
        this.lv_commCode = pResult
        //console.log('fn_SetCommCodeResult ' , pResult)
        //리디오 버튼(이체의사 확인방법) 값 처리....고민 다른것은 하드코딩인데...이항목만 공통코드 처리???
      },

      fn_searchCode(){
        
        this.eaiCommObj.lv_vm = this
        //this.eaiCommObj.trnstId = 'txTSSTS49S9' // selectRP230000CodeList
        this.eaiCommObj.auth = 'S'
        this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570140_S'
        
        this.eaiCommObj.params = {
          //dayChkYn      :'N'
          cdDtl        : '434'  //434
        }
        
        FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_searchCodeResult)
      },

      fn_searchCodeResult(pResultData){
        console.log('fn_searchCodeResult ' , pResultData.data.tukemk)
        let t_data = pResultData.data.tukemk

        //console.log('t_data ' , t_data)
        if( t_data.length > 0 ) {
          //let fnc_inst_Info = t_data.filter(item => FSCommUtil.gfn_nvl(item.cgb1) === '1')  // AS-IS 는 cgb1 값이 1이다.. 
          // 그리고 cnm 값이 모두 ' ' 인데 우짜냐...
          // 일단 cdDtl 로 표기하자
          let fnc_inst_Info = t_data.filter(item => item.cgb1 === "1")
          fnc_inst_Info.forEach((item, idx) => {
            //this.lv_ed_sbx_bfcer_fnc_inst_list.push({value: item.cdDtl, text: item.cnm, hiddenValue: item.cdDtl})
            this.lv_ed_sbx_bfcer_fnc_inst_list.push({value: item.cdDtl, text: item.cnm, hiddenValue: item.cdDtl})
          })
        }  

      },

      /******************************************************************************
       * Function명 : fn_Init
       * 설명       : 화면 초기화
       ******************************************************************************/
      fn_Init() {
        console.log('[MSPFS106M] fn_Init...')

        // 검색조건
        this.lv_ed_accn_no            = FSCommUtil.gfn_dataSet(1, '', false, '', 'ed_accn_no')      // 계좌번호
        this.lv_ed_acct_gb            = FSCommUtil.gfn_dataSet(1, '', true, '', 'ed_acct_gb')       // 계좌종류      // 계좌종류
        this.lv_ed_cust_nm            = FSCommUtil.gfn_dataSet(1)                                   // 고객명
        this.lv_ed_secure_password    = FSCommUtil.gfn_dataSet(1)                                   // 비밀번호

        this.lv_cust_info             = {}                                                          // 고객정보
        this.lv_ep200000_info         = {}                                                          // 연금이전 신청/취소 정보
        this.lv_acct_list             = []                                                          // 계좌조회목록

        // 고객정보 초기화
        this.$refs.fsCustInfo.fn_Init()

        this.lv_ed_rdo_rptType01                = FSCommUtil.gfn_dataSet(0, '', false)                            // 기존 연금계좌유형
        this.lv_ed_rad_ent_ymd_sc_cd            = FSCommUtil.gfn_dataSet(0, '', false)                           // 가입일 구분
        this.lv_ed_rad_trsf_opin_cnf_mth_cd     = FSCommUtil.gfn_dataSet(0, '', false)                           // 이체의사 확인방법
        this.lv_ed_rad_apl_canc_yn              = FSCommUtil.gfn_dataSet(0, '', false)                           // 취소여부
        this.lv_ed_rad_acpn_path_cd             = FSCommUtil.gfn_dataSet(0, '', false)                           // 접수경로

        //this.lv_ed_rdo_rptType01.value = '10'

/*
        this.lv_ed_sbx_bfcer_fnc_inst_cd         = FSCommUtil.gfn_dataSet(1, '', true, '', 'ed_sbx_bfcer_fnc_inst_cd')       // 기존 금융기관
        this.lv_ed_ibx_rmtn_fnc_inst_fof_nm      = FSCommUtil.gfn_dataSet(1)                                   // 지점명
        this.lv_ed_ibx_rmvl_obj_bfcer_accn_cntnt = FSCommUtil.gfn_dataSet(1)                              // 기존 계좌번호
        this.lv_ed_cal_fst_ent_ymd               = FSCommUtil.gfn_dataSet(1)                                   // 최초 가입일자
*/        

        this.lv_ed_sbx_bfcer_fnc_inst_cd         = FSCommUtil.gfn_dataSet(1, '', true, '', 'ed_sbx_bfcer_fnc_inst_cd')       // 기존 금융기관
        this.lv_ed_ibx_rmtn_fnc_inst_fof_nm  = {}   // 지점명
        this.lv_ed_ibx_rmvl_obj_bfcer_accn_cntnt = {}   // 기존 계좌번호
        this.lv_ed_cal_fst_ent_ymd  = {}                // 최초가입일자

        /*
        this.lv_ed_rdo_rptType01  = {}                  // 기존 연금계좌유형
        this.lv_ed_rad_ent_ymd_sc_cd  = {}              // 가입일 구분
        this.lv_ed_rad_trsf_opin_cnf_mth_cd  = {}       // 이체의사 확인방법
        this.lv_ed_rad_apl_canc_yn  = {}                // 취소여부
        this.lv_ed_rad_acpn_path_cd  = {}                // 접수경로
        */

        // 보안키패드 초기화
        this.lv_isClear = !this.lv_isClear ? true : false

        this.fn_setRP230001SDisable(true)


        this.btn_confirm_disabled = true

      },


      /******************************************************************************
       * Function명 : fn_AlertPopup
       * 설명       : alert popup 호출(공통)
       ******************************************************************************/      
      fn_AlertPopup (type, pPopupObj) {
        if( !FSCommUtil.gfn_isNull(pPopupObj) ) {        
          // 공통 얼럿 팝업 오브젝트 초기화
          FSInfoUtil.initAlertPopObj(type, this.pAlertPopupObj, pPopupObj)
        }
        this.$refs.alertPopup.fn_Open()
      },


      /******************************************************************************
       * Function명 : fn_IsActive
       * 설명       : 고객정보 펼치기/접기
       ******************************************************************************/
      fn_IsActive() {
        this.isActive = !this.isActive;
      },


      /******************************************************************************
       * Function명 : fn_CustInfo, fn_SetEdCustName
       * 설명       : 고객정보조회
       ******************************************************************************/
      fn_CustInfo() {
        console.log('fn_CustInfo.....')

        this.pCustInfoObj.custNo = FSCommUtil.gfn_substr(this.lv_ed_accn_no.value, 0, 7)
        this.$refs.fsCustInfo.fn_CustInfoSearch()
        this.fn_CustAddr(this.pCustInfoObj.custNo)
      },

      fn_SetEdCustName(val) {
        // 고객명
        this.lv_ed_cust_nm.value = val
      },
      fn_SetEdCustInfo(custObject) {
        this.lv_cust_info                    = custObject     
      },

      fn_CustAddr(bfcer_cust_no) {
        console.log('fn_CustAddr...')

        this.eaiCommObj.lv_vm = this
        this.eaiCommObj.auth = 'S'
        this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570293_S'
        this.eaiCommObj.params = {
          bfcer_cust_no : bfcer_cust_no,     // 고객번호
        }

        FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_CustAddrResult)
      },

      fn_CustAddrResult(pResultData) {
        console.log('fn_CustAddrResult...')

        let t_popupObj = {}
        let t_data = pResultData.data
        

        if( t_data.error_msg == '809900' ) {
          console.log('cust_hom_addr:' + t_data.cust_hom_pstcd_addr + ' ' + t_data.cust_hom_dtlad_encr)
          this.lv_cust_hom_addr = t_data.cust_hom_pstcd_addr + ' ' + t_data.cust_hom_dtlad_encr

        } else {
          this.lv_cust_hom_addr = ''
        }
      },


      /******************************************************************************
       * Function명 : fn_AccnNoList, fn_AccnNoListResult
       * 설명       : 계좌목록조회
       ******************************************************************************/
      fn_AccnNoList(pAccnNo) {
        console.log('fn_AccnNoList.....')

        let t_data = {bfcer_cstgr_no : pAccnNo, input_check : '14', bfcer_accn_stat_cd : 'Y', int_tr_yn : 'Y'}
        FSInfoUtil.commInfo(this, 2, t_data, this.fn_AccnNoListResult)
      },

      fn_AccnNoListResult(pResultData) {
        console.log('fn_AccnNoListResult.....')

        let t_data = pResultData.data.sy000000_O_00VO
        console.log('계좌목록 : ' , t_data)

        // 계좌목록조회 초기화
        this.lv_acct_list = []
        this.lv_ed_acct_gb.disabled = true

        let lv_acct_idx = 0
        let bfcer_accn_no = this.lv_ed_accn_no.value.toString() + this.lv_ed_acct_gb.value.toString() // 라우터 파라미터로 들어올 경우 
        if( t_data.length > 0 ) {
          t_data.forEach((item, idx) => {
            //this.lv_acct_list.push({value: idx, text: item.acct_no_nm, hiddenValue: item.bfcer_accn_no})
            // 연금계좌 체크 후 연금계좌 alert 로직 생성, 수익증권연금지급주기코드 = 10인 경우 연금계좌로 인식
            /* 왜 들어간걸까???
            if ( item.bfcer_anut_pym_cyl_cd != '10' ) { //연금계좌 Check
              //console.log('연금계좌 : ' , item.bfcer_anut_pym_cyl_cd)                
              this.fn_Init()
              let t_popupObj = {content: "검색할 계좌가 없습니다."}
              this.fn_AlertPopup(0, t_popupObj)
            }
            */
            if ( item.bfcer_accn_no == bfcer_accn_no ) {
              lv_acct_idx = idx
            }
            this.lv_acct_list.push({value: idx, text: item.acct_no_nm, hiddenValue: item.bfcer_accn_no})
          })
          
          // 계좌목록조회 결과 첫번째 선택 및 활성화
          this.lv_ed_acct_gb.value = lv_acct_idx
          this.lv_ed_acct_gb.disabled = false

          // 계좌정보조회 결과 초기화
          this.fn_CustAccnInfoClear()

          // 고객정보조회
          this.fn_CustInfo()
          
        } else {
          
          this.fn_Init()
          let t_popupObj = {content: "검색할 계좌가 없습니다."}
          this.fn_AlertPopup(0, t_popupObj)
        }
      },


      /******************************************************************************
       * Function명 : fn_CustAccnInfoSearch, fn_CustAccnInfoSearchResult
       * 설명       : 고객별보유계좌정보조회
       ******************************************************************************/
      fn_searchList() {
        console.log('fn_searchList.....')

        if( FSCommUtil.gfn_length(this.lv_ed_accn_no.value) == 0 ) {
            FSCommUtil.gfn_validate(this, '계좌번호를 확인하세요.')
            this.lv_ed_accn_no.error = true
            this.$refs['ed_accn_no'].focus()          
            return
        }      
        if(this.isMobile && this.isMtrans) {
          if( FSCommUtil.gfn_length(this.lv_masked_val) != 4 ) {
            FSCommUtil.gfn_validate(this, '비밀번호가 입력되지 않았거나 형식에 맞지 않습니다. 확인하여 주십시오.')
            this.lv_ed_secure_password.error = true
            this.$refs['ed_secure_password'].focus()
            return 
          }
        }
        else {
          if( FSCommUtil.gfn_length(this.lv_ed_secure_password.value) == 0 ) {
            FSCommUtil.gfn_validate(this, '비밀번호가 입력되지 않았거나 형식에 맞지 않습니다. 확인하여 주십시오.')
            this.lv_ed_secure_password.error = true
            this.$refs['ed_secure_password'].focus()
            return 
          }
        }

        this.eaiCommObj.lv_vm = this
        //this.eaiCommObj.trnstId = 'txTSSTS49S9' // selectRP230000Main
        this.eaiCommObj.auth = 'S'
        this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570141_S'
        
        console.log('계좌선택 값 : ' , this.lv_ed_acct_gb.value)


        let acct_info     = this.lv_acct_list.filter(item => item.value === this.lv_ed_acct_gb.value)

        if(acct_info.length == 0){
            FSCommUtil.gfn_validate(this, '계좌번호를 확인하세요.')
            this.lv_ed_accn_no.error = true
            this.$refs['ed_accn_no'].focus()          
            return
        }

        console.log('acct_info ', acct_info.length)

        let bfcer_accn_no = acct_info[0].hiddenValue


        this.eaiCommObj.params = {
          bfcer_accn_no   : bfcer_accn_no
          ,secure_passv   : this.lv_ed_secure_password.value
          ,tel_use_yn     : ''
          ,ars_pswn_yn    : ''
        }
 
        FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_searchListResult)
        
        

      },

      fn_searchListResult(pResultData){

        let t_data = pResultData.data
        
        let sToDay = FSCommUtil.gfn_strToday()
        console.log(FSCommUtil.gfn_strToday())
        // 확인버튼 활성화
        // 취소여부 활성화
        // 기존연금 계좌유형 0번재 설정
        
        // 조건 1
        // 값이 결과값에 bfcer_accn_no 정보가 없으면
        // 기존 금융기관 select box 0 번째 설정
        // 취소여부 값 N 으로 설정
        // 가입일 구분 값 20으로 설정
        // 이체의사 확인방법 10으로 설정
        // 확정 버튼 활성화
        // 메시지호출 --> #1555화면 조회 후 IRP 가입이력 확인 바랍니다. 특이건 본사 확인 요청. 

        // 조건 2
        // acpn_ymd 가  var sToDay = this.gfn_getDate("yyyyMMdd")  
        //  acpn_ymd 와 sToDay 틀리면


        //console.log('fn_searchListResult ' , pResultData.data.tfor03400_O_VO)

        if (FSCommUtil.gfn_trim(t_data.error_msg) == '') {
          this.lv_ep200000_info =  pResultData.data.tfor03400_O_VO

          //console.log('this.lv_ep200000_info ' , this.lv_ep200000_info[0].bfcer_accn_no)
          
          let bfcer_accn_no         =  this.lv_ep200000_info[0].bfcer_accn_no
          let acpn_ymd              =  this.lv_ep200000_info[0].acpn_ymd
          let anut_trsf_prg_stat_cd =  this.lv_ep200000_info[0].anut_trsf_prg_stat_cd
          let acpn_rjt_yn           =  this.lv_ep200000_info[0].acpn_rjt_yn

          this.lv_ed_ibx_rmtn_fnc_inst_fof_nm.value = this.lv_ep200000_info[0].rmtn_fnc_inst_fof_nm
          this.lv_ed_ibx_rmvl_obj_bfcer_accn_cntnt.value = this.lv_ep200000_info[0].rmvl_obj_bfcer_accn_cntnt
          this.lv_ed_cal_fst_ent_ymd.value = FSCommUtil.gfn_dateReplace(1, this.lv_ep200000_info[0].fst_ent_ymd)
          this.lv_ed_rad_trsf_opin_cnf_mth_cd.value = this.lv_ep200000_info[0].trsf_opin_cnf_mth_cd
          this.lv_ed_rad_ent_ymd_sc_cd.value = this.lv_ep200000_info[0].ent_ymd_sc_cd
          this.lv_ed_rad_acpn_path_cd.value = this.lv_ep200000_info[0].acpn_path_cd
          this.lv_ed_rad_apl_canc_yn.value = this.lv_ep200000_info[0].apl_canc_yn

          
          this.lv_ed_sbx_bfcer_fnc_inst_cd.disabled = false  
          let bfcer_inst_Info = this.lv_ed_sbx_bfcer_fnc_inst_list.filter(item => item.hiddenValue === this.lv_ep200000_info[0].bfcer_fnc_inst_cd)
          
          //if(FSCommUtil.gfn_length(this.lv_ep200000_info[0].bfcer_fnc_inst_cd) > 0 ){
          this.lv_ed_sbx_bfcer_fnc_inst_cd.value = this.lv_ep200000_info[0].bfcer_fnc_inst_cd
          //}
    
          this.btn_confirm_disabled = false
          this.lv_ed_rad_apl_canc_yn.disabled = false
          this.lv_ed_rdo_rptType01.value = '10'
          
          if( FSCommUtil.gfn_length(bfcer_accn_no) == 0 ) {
            // this.lv_ed_sbx_bfcer_fnc_inst_cd.value = this.lv_ed_sbx_bfcer_fnc_inst_list[0].value
            this.lv_ed_sbx_bfcer_fnc_inst_cd.value = ''

            // this.lv_ed_sbx_bfcer_fnc_inst_cd.disabled = true
            this.lv_ed_rad_apl_canc_yn.value = 'N'
            this.lv_ed_rad_ent_ymd_sc_cd.value = '20' 
            this.lv_ed_rad_trsf_opin_cnf_mth_cd.value = '10' 
            this.btn_confirm_disabled = false

            FSCommUtil.gfn_validate(this, '#1555화면 조회 후 IRP 가입이력 확인 바랍니다. 특이건 본사 확인 요청.')
            
            this.fn_setRP230001SDisable2(false)

          }else{
            if (acpn_ymd != sToDay) {        //접수일자가 오늘이 아니면
              console.log('1')
              if( FSCommUtil.gfn_length(anut_trsf_prg_stat_cd) != 0 && FSCommUtil.gfn_isNum(anut_trsf_prg_stat_cd) ) {      // 접수 통보 후면 비활성
                console.log('2')
                if(anut_trsf_prg_stat_cd >= 40)  { 
                  console.log('3')
                  this.fn_setRP230001SDisable(true)
                }else{
                  console.log('4')
                  if (acpn_rjt_yn == "Y") {
                    console.log('5')
                    this.fn_setRP230001SDisable(true)
                  }else{
                    console.log('6')
                    this.fn_setRP230001SDisable(false)
                  }
                }
              }   
            }else{
              console.log('7')
              this.lv_ed_rad_apl_canc_yn.disabled = true    
            }
          }
        }
        else {
          let t_popupObj = {content: FSMessageUtil.commMessage([], t_data.error_msg)[t_data.error_msg.split('||')[0]]}
          this.fn_AlertPopup(0, t_popupObj)
        }       
      },

      fn_CustAccnInfoClear() {
        console.log('fn_CustAccnInfoClear.....')

     
      },   
      
      fn_setRP230001SDisable(boolean){

        this.lv_ed_sbx_bfcer_fnc_inst_cd.disabled = boolean
        this.lv_ed_ibx_rmtn_fnc_inst_fof_nm.disabled = boolean
        this.lv_ed_ibx_rmvl_obj_bfcer_accn_cntnt.disabled = boolean
        //this.lv_ed_cal_fst_ent_ymd.disabled = boolean   
        this.lv_ed_rdo_rptType01.disabled = true
        this.lv_ed_rad_trsf_opin_cnf_mth_cd.disabled = boolean
        this.lv_ed_rad_ent_ymd_sc_cd.disabled = boolean
        this.lv_ed_rad_apl_canc_yn.disabled = boolean
        //this.lv_ed_rad_acpn_path_cd.disabled = boolean
        this.btn_confirm_disabled = boolean
      
      },

      fn_setRP230001SDisable2(boolean){

        this.lv_ed_sbx_bfcer_fnc_inst_cd.disabled = boolean
        this.lv_ed_ibx_rmtn_fnc_inst_fof_nm.disabled = boolean
        this.lv_ed_ibx_rmvl_obj_bfcer_accn_cntnt.disabled = boolean
        // this.lv_ed_cal_fst_ent_ymd.disabled = boolean   
        // this.lv_ed_rdo_rptType01.disabled = boolean
        this.lv_ed_rad_ent_ymd_sc_cd.disabled = boolean
        this.lv_ed_rad_trsf_opin_cnf_mth_cd.disabled = boolean
        this.lv_ed_rad_apl_canc_yn.disabled = boolean
        // this.lv_ed_rad_acpn_path_cd.disabled = boolean
        this.btn_confirm_disabled = boolean
      
      },

      /******************************************************************************
       * Function명 : fn_SearchValid
       * 설명       : 필수값 검증 후 조회
       ******************************************************************************/
      fn_SearchValid(event, flag) {
        console.log('fn_SearchValid.....')

				let t_accn_no = this.lv_ed_accn_no.value
        // if( flag == 'P' ) {
        //   t_accn_no = (event.clipboardData || window.clipboardData).getData('text').replace(/[^0-9]/g, '').substr(0,10)
        // } else {
        //   if( !FSCommUtil.gfn_isNull(this.lv_ed_accn_no.value) ) {
        //     t_accn_no = this.lv_ed_accn_no.value.replace(/[^0-9]/g, '').substr(0,10)              
        //   }
        // }

        if( FSCommUtil.gfn_length(t_accn_no) >= 10 ) {
          this.lv_ed_accn_no.error = false

          if( !FSCommUtil.gfn_isNum(t_accn_no) ) {
            FSCommUtil.gfn_validate(this, '계좌번호 형식에 맞지 않습니다.')
            this.lv_ed_accn_no.error = true
            this.$refs['ed_accn_no'].focus()
            return 

          } else {
            if( FSCommUtil.gfn_isNum(event.key) || event.key == 'v' || flag == 'P' || this.isMobile ) {
              // this.lv_ed_accn_no.value = FSCommUtil.gfn_mask(t_accn_no)

              // 계좌목록조회
              this.fn_AccnNoList(t_accn_no)
            }
          }
          
        } else if( t_accn_no.length > 7 || event.key == 'Delete' || event.key == 'Backspace' ) {
          // this.lv_ed_accn_no.value = FSCommUtil.gfn_mask(t_accn_no)

          // 계좌목록/고객명 초기화
          this.lv_acct_list = []
          this.lv_ed_acct_gb.disabled = true
          this.lv_ed_cust_nm.value = ''

          // 고객정보 펼치기 정보 초기화
          this.$refs.fsCustInfo.fn_Init()
        }

      },


      /***************************************************************************************
       *	Function		: fn_confirm
      *	Parameter		:
      *	Return			:
      *	Desc			: 확인
      *****************************************************************************************/
      fn_confirm(){

        if( FSCommUtil.gfn_length(this.lv_ed_cal_fst_ent_ymd.value) == 0 ) {
            FSCommUtil.gfn_validate(this, '최초가입일자가 설정되어있지 않습니다.')
            this.lv_ed_cal_fst_ent_ymd.error = true
            if(this.lv_ed_cal_fst_ent_ymd.disabled === false) this.$refs['ed_cal_fst_ent_ymd'].focus()  
            return
        }      

        if( FSCommUtil.gfn_length(this.lv_ed_sbx_bfcer_fnc_inst_cd.value) == 0 ) {
          FSCommUtil.gfn_validate(this, '금융기관을 선택하세요.')
          this.lv_ed_sbx_bfcer_fnc_inst_cd.error = true
          if(this.lv_ed_sbx_bfcer_fnc_inst_cd.disabled === false) this.$refs['ed_sbx_bfcer_fnc_inst_cd'].focus()
          return 
        }  

        if( FSCommUtil.gfn_length(this.lv_ed_ibx_rmvl_obj_bfcer_accn_cntnt.value) == 0 ) {
          FSCommUtil.gfn_validate(this, '기존계좌번호를 입력하세요.')
          this.lv_ed_ibx_rmvl_obj_bfcer_accn_cntnt.error = true
          if(this.lv_ed_ibx_rmvl_obj_bfcer_accn_cntnt.disabled === false) this.$refs['ed_ibx_rmvl_obj_bfcer_accn_cntnt'].focus()
          return 
        }          
 

        let anut_rmvl_sno = this.lv_ep200000_info[0].anut_rmvl_sno
        if( FSCommUtil.gfn_length(anut_rmvl_sno) == 0 ) {
          let t_popupObj = {
            cancel: true,              
            confirm: true,
            confirmFunc: this.fn_save,
            content: "신청 하시겠습니까?"
          }
          this.fn_AlertPopup(0, t_popupObj)

        }else{

          // 변경된 속성이 존재는지 확인 필요
          let bChaged = false

          console.log('~~~~~~~~')
          console.log(this.lv_ed_sbx_bfcer_fnc_inst_cd.value , this.lv_ep200000_info[0].bfcer_fnc_inst_cd)
          console.log(this.lv_ed_rad_apl_canc_yn.value , this.lv_ep200000_info[0].apl_canc_yn)
          console.log('~~~~~~~~')
          if(this.lv_ed_sbx_bfcer_fnc_inst_cd.value !== this.lv_ep200000_info[0].bfcer_fnc_inst_cd ||
             this.lv_ed_ibx_rmtn_fnc_inst_fof_nm.value !== this.lv_ep200000_info[0].rmtn_fnc_inst_fof_nm ||
             this.lv_ed_ibx_rmvl_obj_bfcer_accn_cntnt.value !== this.lv_ep200000_info[0].rmvl_obj_bfcer_accn_cntnt ||
             this.lv_ed_rad_ent_ymd_sc_cd.value !== this.lv_ep200000_info[0].ent_ymd_sc_cd ||
             this.lv_ed_rad_trsf_opin_cnf_mth_cd.value !== this.lv_ep200000_info[0].trsf_opin_cnf_mth_cd ||
             this.lv_ed_rad_apl_canc_yn.value !== this.lv_ep200000_info[0].apl_canc_yn
          ) bChaged = true

          if(!bChaged){
            FSCommUtil.gfn_validate(this, '변경된 항목이 없습니다.')
            return
          }

          let t_popupObj = {
            cancel: true,              
            confirm: true,
            confirmFunc: this.fn_save,
            content: "신청 하시겠습니까?" //변경하시겠습니까? => 신청 하시겠습니까? 로 수정요청
          }
          this.fn_AlertPopup(0, t_popupObj)

        }

      },


      fn_save(){

        console.log('확인 버튼 클릭시 ')
        this.eaiCommObj.lv_vm = this
        //this.eaiCommObj.trnstId = 'txTSSTS49S9' // insertTFOR03400
        this.eaiCommObj.auth = 'I'
        this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570199_S'
        
        let acct_info     = this.lv_acct_list.filter(item => item.value === this.lv_ed_acct_gb.value)
        let bfcer_accn_no = acct_info[0].hiddenValue

        this.eaiCommObj.params = {
            bfcer_accn_no             : bfcer_accn_no
            ,anut_rmvl_sno             : this.lv_ep200000_info[0].anut_rmvl_sno               // 연금이전 일련번호
            ,acpn_ymd                  : this.lv_ep200000_info[0].acpn_ymd
            ,bfcer_fnc_inst_cd         : this.lv_ed_sbx_bfcer_fnc_inst_cd.value               //기존 금융기관
            ,rmtn_fnc_inst_fof_nm      : this.lv_ed_ibx_rmtn_fnc_inst_fof_nm.value            //지점명
            ,rmvl_obj_bfcer_accn_cntnt : this.lv_ed_ibx_rmvl_obj_bfcer_accn_cntnt.value       //기존 계좌번호
            
            ,ent_ymd_sc_cd             : this.lv_ed_rad_ent_ymd_sc_cd.value                   //가입일 구분
            ,trsf_opin_cnf_mth_cd      : this.lv_ed_rad_trsf_opin_cnf_mth_cd.value            //이체의사 확인방법
            ,apl_canc_yn               : this.lv_ed_rad_apl_canc_yn.value                     //취소여부
            ,bfcer_tlgm_acpn_no        : this.lv_ep200000_info[0].bfcer_tlgm_acpn_no
            ,bfcer_tlgm_mng_no         : this.lv_ep200000_info[0].bfcer_tlgm_mng_no
            ,acpn_rjt_yn               : this.lv_ep200000_info[0].acpn_rjt_yn
            ,fst_ent_ymd               : this.lv_ep200000_info[0].fst_ent_ymd
            ,trnc_accn_anut_typ        : this.lv_ep200000_info[0].trnc_accn_anut_typ
        }
        
        FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_saveResult)
      },

      fn_saveResult(pResultData){
        console.log('연금이전 fn_saveResult....')
        let lv_vm = this
        let t_data = pResultData.data

        let tmpContent = [] 
        let t_popupObj = {}
        let t_type    = 0       
        let t_procTyp = lv_vm.getStore('fsStore').getters.getBasInfo.data.procTyp

        tmpContent = ['연금이전 신청/취소(타사 → 당사)가 완료되었습니다.']  
        
        if (t_data.error_msg === '809905' || FSCommUtil.gfn_trim(t_data.error_msg) == '') {
          if( this.lv_isProcess) {
            if(t_procTyp == 'grpNew') {     // 그룹신규 프로세스 일때                  
              t_type = 7
              tmpContent.push('인터넷 펀드거래 서비스신청 화면으로 이동합니다.')
            } else if(t_procTyp == 'accnNew') {   // 계좌신규 프로세스일때 마지막 화면         
              t_type = 9 
              this.getStore('fsStore').dispatch('IS_LAST_START')
            }            
          } else {
            t_type = 9            
          } 

          t_popupObj = {              
            confirm: true,
            confirmFunc : lv_vm.fn_ReportPrint,
            confirmData : {
              bfcer_cust_no : lv_vm.lv_ed_accn_no.value
            },
            contentTitle: '연금이전 신청/취소 완료',
            content: tmpContent,
          } 
          
          this.fn_AlertPopup(t_type, t_popupObj)

          // 연금이전 신청/취소 성공 메세지  809901   확인 필요!!!
          // if( t_data.error_msg == '809901' ) {
            
          /* } else {
            
            if( FSCommUtil.gfn_isNull(t_data.dummy) ) {
              // 소스 또는 쿼리 에러발생시
              t_popupObj = {
                content: "연금이전 신청/취소(타사 → 당사)에 실패하셨습니다.",
              }

            } else {
              // 주민등록번호 검증오류시
              t_popupObj = {
                confirm: true,
                // confirmFunc: this.fn_ForceInsert,
                content: "오류가 발생했습니다. 관리자에게 문의 바랍니다",
              }
            }
          }
          */
        }
        else {
          let t_popupObj = {content: FSMessageUtil.commMessage([], t_data.error_msg)[t_data.error_msg.split('||')[0]]}
          this.fn_AlertPopup(0, t_popupObj)
        }  

        
      },
      
      
      fn_saveResult_old(pResultData){
        console.log('연금이전 fn_saveResult....')
        const lv_vm = this

        let t_popupObj = {}
        let t_data = pResultData.data
        let t_procTyp = lv_vm.getStore('fsStore').getters.getBasInfo.data.procTyp

        if( this.lv_isProcess) {
          if(t_procTyp == 'grpNew') {
            t_popupObj = {
              cancel: false,
              confirm: true,
              confirmFunc: this.fn_GoNextPage,
              content: '연금이전 신청/취소(타사 → 당사)가 완료되었습니다. \n인터넷 펀드거래 서비스신청 화면으로 이동합니다.',
            }
            // 그룹 신규 프로세스 : 전자서식 호출
            this.fn_NextPage('G')
          } else if(t_procTyp == 'accnNew') {
            // 계좌 신규 프로세스 : 전자서식 호출
            this.getStore('fsStore').dispatch('IS_LAST_START')
            this.fn_NextPage('A')
          }
        } else {
          // 단독화면인 경우 전자서식 호출          
          this.fn_NextPage('O')
        }        

        // 연금이전 신청/취소 성공 메세지  809901   확인 필요!!!
        // if( t_data.error_msg == '809901' ) {
          
        /* } else {
          
          if( FSCommUtil.gfn_isNull(t_data.dummy) ) {
            // 소스 또는 쿼리 에러발생시
            t_popupObj = {
              content: "연금이전 신청/취소(타사 → 당사)에 실패하셨습니다.",
            }

          } else {
            // 주민등록번호 검증오류시
            t_popupObj = {
              confirm: true,
              // confirmFunc: this.fn_ForceInsert,
              content: "오류가 발생했습니다. 관리자에게 문의 바랍니다",
            }
          }
        }
        */

       
      },

      /******************************************************************************
       * Function명 : fn_movePage_SIGN
       * 설명       : 전자서명화면으로 이동
       ******************************************************************************/
      fn_movePage_SIGN () {
        let t_type     = 9
        let tmpContent = []
        tmpContent.push('연금이전 신청/취소(타사 → 당사)가 완료되었습니다.')
        let t_popupObj = {
          single       : true,
          confirm      : true,
          confirmFunc  : this.fn_ReportPrint(),
          confirmData  : {
             bfcer_cust_no: FSCommUtil.gfn_substr(this.lv_ed_accn_no.value, 0, 7)
          },
          content      : tmpContent,
          contentTitle : '신청/취소 완료',
        }
        
        this.fn_AlertPopup(t_type, t_popupObj)
      },
     
     
      /******************************************************************************
       * Function명 : fn_NextPage
       * 설명       : 연금이전 신청/취소 후 다음 페이지 팝업
       ******************************************************************************/    
      fn_NextPage(type) {      
        let t_procTyp = this.getStore('fsStore').getters.getBasInfo.data.procTyp

        if (FSCommUtil.gfn_isNull(type)){        
          return
        } else {
          if(type !== 'G' && type !== 'A' && type !== 'O'){
            return
          }
        }        

        let lv_Vm = this;
        let tmpContentTitle = ''        
        let tmpContent = [] 
        switch (type) {
          case 'G':
            tmpContentTitle = '그룹신규'
            tmpContent = ['연금이전 신청/취소(타사 → 당사)가 완료되었습니다.']
            break;
          case 'A':
            tmpContentTitle = '계좌신규'
            tmpContent = ['연금이전 신청/취소(타사 → 당사)가 완료되었습니다.']            
            break;
        }

        let t_type = 9
 
        if(this.isProcessCheck){
          if(t_procTyp == 'grpNew') {
            tmpContent.push('인터넷펀드거래서비스 화면으로 이동합니다.')
            t_type = 7 // 프로세스 진행 중 마지막 화면이 아닐 경우
          } 
        } 
        
        let bfcer_cust_no = FSCommUtil.gfn_substr(this.lv_ed_accn_no.value, 0, 7)
        let t_popupObj = {
          confirm: true,
          confirmFunc: lv_Vm.fn_ReportPrint,
          confirmData: {
            bfcer_cust_no: bfcer_cust_no
          },
          content: tmpContent,
          contentTitle: tmpContentTitle,
        }
        
        lv_Vm.fn_AlertPopup(t_type, t_popupObj)        
      },


      /******************************************************************************
       * Function명 : fn_ReportPrint
       * 설명       : 전자서식 호출
       ******************************************************************************/
      async fn_ReportPrint () {
        console.log('fn_ReportPrint....')        

        let formList = []

        // 커버발행 파라미터 저장
        this.$bizUtil.fsUtils.saveCvrPblParam(this, {bfcer_cust_no: this.lv_cust_no})
        
        let FS000025 = {formId: 'FS000025'} // as-is : RCO000111 , 연금저축계좌 이체 신청서 및 이체(접수)통보서

        let acct_info     = this.lv_acct_list.filter(item => item.value === this.lv_ed_acct_gb.value)
        let btransBankNm  = this.lv_ed_sbx_bfcer_fnc_inst_list.filter(item => item.value === this.lv_ed_sbx_bfcer_fnc_inst_cd.value)[0].text
        //let bfcer_accn_no = this.lv_ed_accn_no.value
        let bfcer_accn_no = acct_info[0].hiddenValue

        let basInfo   = this.getStore('fsStore').getters.getBasInfo.data
        let link_acct_no = '' //신청정보 연계계좌 
        
        //FS000019(연금계좌 신규거래신청서)의 리포트에서 연결계좌정보 찾기
        if (basInfo.rprtInfo.filter(item => item.formId === 'FS000019').length > 0) {
          link_acct_no = basInfo.rprtInfo.filter(item => item.formId === 'FS000019')[0].SingleValueMap.link_acct_no
        }

        FS000025.mappingData = {
          tukemk : btransBankNm,//t_src.tukemk, 	// 이체하는 기관 (다를경우 화면단에서 입력)
          custNm : this.lv_ed_cust_nm.value, 	// 고객명 (다를경우 화면단에서 입력)
          acpnYmd : FSCommUtil.gfn_strToday(),//this.lv_ep200000_info[0].acpn_ymd, 	// 접수일자 (다를경우 화면단에서 입력)
          acpnNo : FSCommUtil.gfn_strToday(),//this.lv_ep200000_info[0].rmvl_inst_acpn_no,//t_src.acpnNo, 	// 접수번호 (다를경우 화면단에서 입력)
          chkYn1 : this.lv_ed_rad_apl_canc_yn.value === 'N' ? '1' : '2', 	// 신청내용 선택 체크박스 (다를경우 화면단에서 입력) (1,2) [1.계좌이체 신청,2.계좌이체 신청 취소]
          chkYn2 : this.lv_ed_rad_trsf_opin_cnf_mth_cd.value, 	// 이체 의사 확인 체크박스 (다를경우 화면단에서 입력) (1,2) [10.전화통화,20.이체하는 금융기관 방문]
          entrName : this.lv_cust_info.fnd_cust_nm,//t_src.entrName, // 가입자 성명 (다를경우 화면단에서 입력)
          entrRrn : this.lv_cust_info.rlnm_cnf_no_encr,//t_src.entrRrn, 	// 가입자 주민번호 (다를경우 화면단에서 입력)
          entrAdd : this.lv_cust_hom_addr,//t_src.entrAdd, 	//  가입자 주소 (다를경우 화면단에서 입력)
          entrTelNo01 : FSCommUtil.gfn_isNull(this.lv_cust_info.bman_celph_dstno_encr) ? '' : this.lv_cust_info.bman_celph_dstno_encr + '-' + this.lv_cust_info.bman_celph_tlnum_no_encr + '-' + this.lv_cust_info.bman_celph_bkno_sno_encr,//t_src.entrTelNo01, //  가입자전화번호 (다를경우 화면단에서 입력)
          btransBankNm : btransBankNm,//t_src.btransBankNm, // 이체하는 계좌번호1 (다를경우 화면단에서 입력)
          btransBankAcctNo : this.lv_ed_ibx_rmvl_obj_bfcer_accn_cntnt.value, //t_src.btransBankAcctNo, // 이체하는 계좌번호2 (다를경우 화면단에서 입력)
          trncAccnAnutTyp : this.lv_ed_rdo_rptType01.value, // 이체하는 계좌유형 (다를경우 화면단에서 입력) (1,2) [10.연금저축계좌,20.개인형 IRP]
          trsAcctNo : this.lv_ep200000_info[0].bfcer_accn_no,//t_src.trsAcctNo, // 이체받을 계좌번호2 (다를경우 화면단에서 입력)
          trncAccntTyp : this.lv_ed_rdo_rptType01.value, // 이체받을 계좌유형 체크박스 (다를경우 화면단에서 입력) (1,2) [10.연금저축계좌,20.개인형 IRP]
          trncAnutEntYmd : this.lv_ed_rad_ent_ymd_sc_cd.value, // 가입일 선택 체크박스 (다를경우 화면단에서 입력) (1,2) [20.이체하는 계좌의 가입일(타사),10.이체받을 계좌의 가입일(당사)]
          repayAcctNo : link_acct_no, // 해지환급금 수령계좌2 (다를경우 화면단에서 입력)
        }
        
        FS000025.params = {
          reportParam: JSON.stringify(this.eaiCommObj.params),
          reportUrl: 'reportRP230000_0111info',
          // reportMrdNm: '/crownix/RCO000111.mrd',
        }
        formList.push(FS000025)
        
        FSInfoUtil.commReportInfo(this, formList, this.fn_GoPage)

        // FSInfoUtil.commReportInfo(this, formList, this.fn_GoPage)
      },

      /******************************************************************************
       * Function명 : fn_GoNextPage
       * 설명       : 인터넷 펀드거래 서비스신청 페이지로 이동
       ******************************************************************************/
      fn_GoPage() {                 
        let paramObj = {}
        let scrnId = 'MSPFS109M'
        paramObj.rmvl_srn_id = 'CU101000'
        paramObj.bfcer_cust_no = FSCommUtil.gfn_substr( this.lv_ed_accn_no.value, 0, 7)

        this.$router.push({ name: scrnId , params: paramObj})

        //  this.$router.push({
        //    name: 'MSPFS109M', 
        //    params: {
        //      bfcer_cust_no: FSCommUtil.gfn_substr(this.lv_ed_accn_no.value, 0, 7) 
        //    }
        //  })        
      },
    }
  }
</script>
<style scoped>
</style>